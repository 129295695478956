



























































































































import { Component, Mixins } from 'vue-property-decorator';

import {
  ServiceManager,
  ServiceType,
  Firebase,
  API,
} from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';

import Loader from '@/components/UI/Loader.vue';
import { PROListAnalyticsAdapter } from 'client-website-ts-library/services/Analytics';
import { ContactCorrelationAdapter } from 'client-website-ts-library/services/CRM/ContactCorrelationAdapter';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Loader,
    ListingSearch,
  },
})
export default class MarketUpdates extends Mixins(View) {
  private step = 0;

  private loading = false;

  private beds = 4;

  private baths = 2;

  private carSpaces = 2;

  private name = '';

  private email = '';

  private phone = '';

  private contactId: string | null = null;

  registerFCM(): void {
    this.loading = true;

    const fcm = ServiceManager.Get(ServiceType.Firebase) as Firebase;

    fcm.Subscribe().then((token: string) => {
      const pla = ServiceManager.Get<PROListAnalyticsAdapter>(ServiceType.Analytics, 'PROListAnalyticsAdapter');

      if (pla !== null) {
        pla.SetFCMToken(token);
      }

      this.loading = false;
      this.step = 1;
    });
  }

  submitPropertyRequirement(): void {
    this.loading = true;

    const postCodes: string[] = [
      '4870',
      '4879',
      '4868',
      '4869',
      '4865',
    ];

    API.CRM.CreateBasicPropertyRequirement(this.contactId!, this.beds, this.baths, this.carSpaces, postCodes).then(() => {
      this.loading = false;
      this.step = 3;
    }).catch(() => {
      this.loading = false;
    });
  }

  createContact(): void {
    this.loading = true;

    const nameParts = this.name.split(' ');

    let firstName;
    let lastName;

    if (nameParts.length >= 2) {
      lastName = nameParts.pop()!;
      firstName = nameParts.join(' ');
    } else if (nameParts.length > 0) {
      firstName = nameParts[0];
      lastName = '';
    } else {
      firstName = '';
      lastName = '';
    }

    API.CRM.CreateContact(firstName, lastName, this.email, this.phone).then((contact) => {
      this.contactId = contact.Id;

      const cca = ServiceManager.Get<ContactCorrelationAdapter>(ServiceType.ContactCorrelation);

      if (cca !== null) {
        cca.SetContactId(contact.Id);
      }

      this.step = 2;
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  }
}
